<template>
    <div class="dialog-inner" style="padding-top:0;">
        <div class="form-col2">
            <div class="form-group-title">
                <h2>分包单位</h2>
            </div>
            <el-row :gutter="20">
                <el-col :span="12">
                    <div class="data-item">
                        <div class="label">项目名称：</div>
                        <div class="text">{{data.projectName}}</div>
                    </div>
                </el-col>
                <el-col :span="12">
                    <div class="data-item">
                        <div class="label">分包单位名称：</div>
                        <div class="text">{{data.supplierName}}</div>
                    </div>
                </el-col>
            </el-row>
            <div class="form-group-title">
                <h2>退宿房间</h2>
                <div class="info-box">
                    房间数：{{ data.roomNum }}
                </div>
            </div>
            <div class="select-room-box select-room-box-1">
                <template v-if="storeyList.length > 0">
                    <div
                        v-for="(storey, si) in storeyList"
                        :key="si"
                        class="item clearfix">
                        <div class="st"
                    >{{ storey.building.name }}{{ storey.storey.name }}</div>
                        <ul>
                            <li
                                v-for="(room, ri) in storey.roomList"
                                :key="si+':'+ri"
                                :style="{ 'cursor': 'default' }"
                            >{{ room.name }}</li>
                        </ul>
                    </div>
                </template>
                <div v-else class="empty">暂无数据</div>
            </div>
            <el-row :gutter="20" class="mt-5">
                <el-col :span="12">
                    <div class="data-item">
                        <div class="label">退房日期：</div>
                        <div class="text">{{data.day}}</div>
                    </div>
                </el-col>
            </el-row>
            <div class="form-group-title">
                <h2>费用扣除项</h2>
                <div class="info-box">
                    <span class="sc">总计：{{ total }}元</span>
                </div>
            </div>
            <div class="datatable-wrap-1">
                <div class="datatable table-edit">
                    <el-table
                        ref="currentTable"
                        :data="data.data"
                        v-loading="tableLoading"
                        row-key="id"
                        element-loading-background="rgba(255, 255, 255, 0.3)"
                        border
                        style="width: 100%">
                        <el-table-column
                            type="selection"
                            width="55"
                            align="center">
                        </el-table-column>
                        <el-table-column
                            label="序号"
                            align="center"
                            width="80">
                            <template slot-scope="scope">
                                <span>{{ scope.$index + 1 }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="item"
                            label="扣除项">
                        </el-table-column>
                        <el-table-column
                            prop="money"
                            label="扣除金额"
                            width="180">
                        </el-table-column>
                        <el-table-column
                            prop="reason"
                            label="扣除原因">
                        </el-table-column>
                    </el-table>
                </div>
            </div>
            <el-row :gutter="20" class="mt-5">
                <el-col :span="12">
                    <div class="data-item">
                        <div class="label">附件：</div>
                        <div v-if="fileList.length > 0" class="text file-box">
                            <div v-for="(file, index) in fileList" :key="index" class="file-item" @click="handleDownLoad(file)">{{ file.name }}</div>
                        </div>
                        <div v-if="fileList.length == 0" class="text file-box-empty">无</div>
                    </div>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
import http from "@/utils/http"
import common from "@/utils/common"
import verify from "@/utils/verify"

export default {
    name: 'CheckoutListDetail',
    props: {
        params: {
            type: Object,
            required: true
        }
    },
    data(){
        return {
            storeyList: [
                // {
                //     building: {
                //         id: 1,
                //         name: '1号楼',
                //     },
                //     storey: {
                //         id: 1,
                //         name: '2层',
                //     },
                //     roomList: [
                //         {
                //             id: 1,
                //             name: '201',
                //         },
                //         {
                //             id: 2,
                //             name: '202',
                //         }
                //     ],
                // },
            ],
            tableLoading: false,
            data: {
                projectName: '',
                supplierName: '',
                checkOutDate: '',
                data: [],
            },
            fileList: [],
        }
    },
    computed: {
        total() {
            let money = 0;

            if (this.data.data) {
                this.data.data.forEach(item => {
                    let m = Number(item.money)
                    if (!isNaN(m)) {
                        money += m;
                    }
                });
            }
           
            return money;
        },
    },
    created() {
        this.loadData();
    },
    methods: {
        ...common,
        ...verify,
        loadData() {
            let params = {};
            params.id = this.params.id;
            let loading = this.load();
            http.get("v1/build/selectSupplierCheckoutDetail", { params: params }).then(req => {
                loading.close();
                if (req.data.code == http.SUCCESS) {
                    let data = req.data.data;
                    let roomList = data.roomName.split(',');

                    let map = {};
                    roomList.forEach(x => {
                        let arr = x.split('-');
                        let buildName = arr[0];
                        let floorName = arr[1];
                        let roomName = arr[2];

                        let key = buildName + '-' + floorName;
                        let item = map[key];

                        if (!item) {
                            item = {
                                building: {
                                    name: buildName,
                                },
                                storey: {
                                    name: floorName,
                                },
                                roomList: [],
                            };

                            map[key] = item;
                        }

                        item.roomList.push({ name: roomName });
                    });

                    let arr = [];
                    for (let key in map) {
                        let item = map[key];
                        arr.push(item);
                    }

                    this.storeyList = arr;

                    if (data.data && data.data.length > 0) {
                        let fileList = []
                        data.data.forEach(item => {
                            item.money = this.toFixed(item.money, 2);
                            if (item.file) {
                                try {
                                    let arr = JSON.parse(item.file);
                                    fileList = fileList.concat(arr);
                                } catch(ex) {
                                    // ex
                                }
                            }
                        });
                        this.fileList = fileList;
                    } else {
                        data.data = [];
                    }

                    this.data = data;
                }
                else {
                    this.showError(req.data.msg);
                }
            }).catch(err => {
                loading.close();
                this.httpCatch(err);
            });
        },
        handleDownLoad(data) {
            let filename = data.name;
            let url = data.url;
            http.download(url, { filename: filename, type: "application/octet-stream" });
        },
    }
};
</script>

<style scoped>
</style>
