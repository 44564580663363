<template>
    <div class="dialog-inner" style="padding-top:0;">
        <el-form ref="form" :model="form" :rules="rules" label-width="100px" :show-message="true" class="form-col2">
            <el-row :gutter="20">
                <el-col :span="24">
                    <el-form-item label="分包单位" prop="supplierId">
                        <el-select v-model="supplierId" placeholder="请选择" size="small" @change="handleSelectSupplier">
                            <el-option 
                                v-for="item in supplierList" 
                                :key="item.id" 
                                :label="item.name" 
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <div class="form-group-title">
                <h2>选择房间</h2>
                <div class="info-box text-success">
                    提示：宿舍所有人退宿后才可进行退房
                </div>
                <div class="btn-box">
                    <el-button type="primary" size="mini" @click="handleSelectAll" :disabled="supplierId == ''">全部退宿</el-button>
                </div>
            </div>
            <div class="select-room-box select-room-box-1">
                <el-form-item label-width="0" prop="roomIdList" style="margin-bottom: 0;">
                    <template v-if="storeyList.length > 0">
                        <div
                            v-for="storey in storeyList"
                            :key="storey.storey.id"
                            class="item clearfix">
                            <div class="st"
                        >{{ storey.building.name }}{{ storey.storey.name }}</div>
                            <ul>
                                <li
                                    v-for="room in storey.roomList"
                                    :key="room.id"
                                    :class="{ 'selected': roomMap[room.id] }"
                                    @click="handleRoomClick(room)"
                                >{{ room.name }}</li>
                            </ul>
                        </div>
                    </template>
                    <div v-else class="empty">暂无数据</div>
                </el-form-item>
            </div>
            <el-row :gutter="20" class="mt-5">
                <el-col :span="12">
                    <el-form-item label="退房日期" prop="checkOutDate">
                        <el-date-picker
                            v-model="form.checkOutDate"
                            type="date"
                            placeholder="选择日期"
                            value-format="yyyy-MM-dd"
                            size="small"
                            :picker-options="pickerOptions">
                        </el-date-picker>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="人员自动退房" prop="isWorkCheckOut">
                        <el-switch
                            v-model="form.isWorkCheckOut"
                            :active-value="1"
                            :inactive-value="0"
                            active-color="#13ce66"
                            inactive-color="#ff4949">
                        </el-switch>
                    </el-form-item>
                </el-col>
            </el-row>
            <div class="form-group-title">
                <h2>费用扣除项</h2>
                <div class="info-box">
                    <span class="sc">总计：{{ total }}元</span>
                </div>
                <div class="btn-box">
                    <el-button type="primary" size="mini" @click="handleDeductionAdd">新增</el-button>
                    <el-button type="danger" size="mini" @click="handleDeductionDelete">删除</el-button>
                </div>
            </div>
            <div class="datatable-wrap-1">
                <div class="datatable table-edit">
                    <el-table
                        ref="currentTable"
                        :data="form.deductionList"
                        v-loading="tableLoading"
                        row-key="id"
                        element-loading-background="rgba(255, 255, 255, 0.3)"
                        border
                        style="width: 100%">
                        <el-table-column
                            type="selection"
                            width="55"
                            align="center">
                        </el-table-column>
                        <el-table-column
                            label="序号"
                            align="center"
                            width="80">
                            <template slot-scope="scope">
                                <span>{{ scope.$index + 1 }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="itemName"
                            label="扣除项">
                            <template slot-scope="scope">
                                <el-form-item
                                    label-width="0"
                                    :prop="'deductionList.'+scope.$index+'.itemName'"
                                    style="margin-bottom: 0;"
                                    :rules="[
                                        { required: true, message: '请输入扣除项', trigger: 'blur' }
                                    ]">
                                    <el-input v-model="scope.row.itemName" size="mini"></el-input>
                                </el-form-item>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="money"
                            label="扣除金额"
                            width="180">
                            <template slot-scope="scope">
                                <el-form-item
                                    label-width="0"
                                    :prop="'deductionList.'+scope.$index+'.money'"
                                    style="margin-bottom: 0;"
                                    :rules="[
                                        { required: true, message: '请输入扣除金额', trigger: 'blur' },
                                        { validator: checkMoney, trigger: 'blur' }
                                    ]">
                                    <el-input v-model="scope.row.money" placeholder="元" size="mini"></el-input>
                                </el-form-item>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="reason"
                            label="扣除原因">
                            <template slot-scope="scope">
                                <el-form-item label-width="0" :prop="'deductionList.'+scope.$index+'.reason'" style="margin-bottom: 0;">
                                    <el-input v-model="scope.row.reason" size="mini"></el-input>
                                </el-form-item>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="附件" prop="date">
                        <el-upload
                            :action="uploadUrl"
                            :on-remove="handleRemove"
                            :on-exceed="handleExceed"
                            :on-success="handleSuccess"
                            :on-error="handleError"
                            :on-change="handleChange"
                            multiple
                            :limit="5"
                            :file-list="initFileList"
                            :disabled="!form.deductionList || form.deductionList.length == 0">
                            <el-button size="small" type="primary" :disabled="!form.deductionList || form.deductionList.length == 0">选择文件</el-button>
                        </el-upload>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20" class="submit-row">
                <el-col :span="24">
                    <el-form-item>
                        <el-button type="primary" @click="handleSubmit" :loading="submitting" size="small">提交</el-button>
                        <el-button @click="resetForm" size="small">重置</el-button>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>

<script>
import http from "@/utils/http"
import common from "@/utils/common"
import verify from "@/utils/verify"

export default {
    name: 'CheckOut',
    props: {
        params: {
            type: Object,
            required: true
        }
    },
    data(){
        return {
            storeyList: [
                // {
                //     building: {
                //         id: 1,
                //         name: '1号楼',
                //     },
                //     storey: {
                //         id: 1,
                //         name: '2层',
                //     },
                //     roomList: [
                //         {
                //             id: 1,
                //             name: '201',
                //         },
                //         {
                //             id: 2,
                //             name: '202',
                //         }
                //     ],
                // },
            ],
            tableLoading: false,
            projectId: '',
            supplierId: '',
            supplierList: [],
            form: {
                roomIdList: [],
                deductionList: [],
                checkOutDate: '',
                isWorkCheckOut: 0,
                file: '',
            },
            roomMap: {},
            rules: {
                roomIdList: [{required: true, message: '请选择房间', trigger: 'change'}],
                checkOutDate: [{required: true, message: '请选择退房时间', trigger: 'change'}],
            },
            submitting: false,
            deductionIndex: 0,
            initFileList: [],
            fileList: [],
            uploadUrl: http.BASE_URL + 'v1/upload',
            pickerOptions: {
                disabledDate(time) {
                    let now = new Date();
                    return time.getTime() > now.getTime() 
                        || !(time.getFullYear() == now.getFullYear() && time.getMonth() == now.getMonth());
                }
            },
        }
    },
    computed: {
        total() {
            let money = 0;
            this.form.deductionList.forEach(item => {
                let m = Number(item.money)
                if (!isNaN(m)) {
                    money += m;
                }
            });
            return money;
        },
    },
    watch: {
        roomMap: {
            immediate: true,
            deep: true,
            handler: function() {
                let list = [];
                this.storeyList.forEach(storey => {
                    if (storey.roomList) {
                        storey.roomList.forEach(room => {
                            if (this.roomMap[room.id]) {
                                list.push(room.id);
                            }
                        });
                    }
                });
                this.form.roomIdList = list;
                if (this.$refs.form) {
                    this.$refs.form.validateField("roomIdList");
                }
            }
        },

    },
    created() {
        if (!this.params.projectId) {
            this.loadSupperList();
            let org = this.getCurrentOrg();
            this.projectId = org.id;
        } else {
            this.loadData();
            this.projectId = this.params.projectId;
            this.supplierId = this.params.id;
        }
    },
    methods: {
        ...common,
        ...verify,
        loadSupperList() {
            http.get("v1/supplier/list").then(req => {
                if (req.data.code == http.SUCCESS) {
                    this.supplierList = req.data.data;
                }
                else {
                    this.showError(req.data.msg);
                }
            }).catch(err => {
                this.httpCatch(err);
            });
        },
        loadData() {
            let params = {};
            params.supplierId = this.supplierId;
            let loading = this.load();
            http.get("v1/build/roomList", { params: params }).then(req => {
                loading.close();
                if (req.data.code == http.SUCCESS) {
                    let data = req.data.data;

                    let map = {};
                    data.forEach(x => {
                        let item = map[x.floorId];
                        if (!item) {
                            item = {
                                building: {
                                    id: x.buildId,
                                    name: x.buildName,
                                },
                                storey: {
                                    id: x.floorId,
                                    name: x.floorName,
                                },
                                roomList: [],
                            };

                            map[x.floorId] = item;
                        }
                        item.roomList.push({id: x.roomId, name: x.roomName, ...x});
                    });

                    let arr = [];
                    for (let key in map) {
                        let item = map[key];
                        arr.push(item);
                    }

                    this.storeyList = arr;
                }
                else {
                    this.showError(req.data.msg);
                }
            }).catch(err => {
                loading.close();
                this.httpCatch(err);
            });
        },
        handleSubmit(){
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.submitting = true;
                    let data = {
                        supplierId: this.supplierId,
                        projectId: this.projectId,
                        buildRoomIds: this.form.roomIdList,
                        day: this.form.checkOutDate,
                        isWorkCheckOut: this.form.isWorkCheckOut,
                        file: this.form.file,
                        remark: ''
                    };
                    data.data = this.form.deductionList.map(x => {
                        return {
                            item: x.itemName,
                            money: x.money,
                            reason: x.reason
                        };
                    });
                    http.post('v1/build/supplierCheckout', data).then(req => {
                        this.submitting = false;
                        if (req.data.code == http.SUCCESS) {
                            this.showSuccess('退房成功', () => {
                                this.$emit('done');
                            });
                        } else {
                            this.showError(req.data.msg);
                        }
                    }).catch(err => {
                        this.submitting = false;
                        this.httpCatch(err);
                    });
                } else {
                    return false;
                }
            });
        },
        handleRoomClick(room) {
            this.$set(this.roomMap, room.id, !this.roomMap[room.id]);
        },
        handleSelectAll() {
            let map = {};
            this.storeyList.forEach(storey => {
                if (storey.roomList) {
                    storey.roomList.forEach(room => {
                        map[room.id] = true;
                    });
                }
            });
            this.roomMap = map;
        },
        handleDeductionAdd() {
            this.form.deductionList.push({
                id: this.deductionIndex++,
                itemName: '',
                money: '',
                reason: '',
            });

            console.log(this.form.deductionList);
        },
        handleDeductionDelete() {
            if (this.$refs.currentTable.selection.length == 0) {
                this.showError('请选择要删除的扣费项！');
            } else {
                this.confirm('确认删除所选扣费项？', function () {
                    let map = {};
                    for (let i = 0; i < this.$refs.currentTable.selection.length; i++) {
                        map[this.$refs.currentTable.selection[i].id] = true;
                    }
                    this.form.deductionList = this.form.deductionList.filter(x => !map[x.id]);
                    if (this.form.deductionList.length == 0) {
                        this.form.file = '';
                        this.initFileList = [];
                        this.fileList = [];
                    }
                });
            }
        },
        handleSelectSupplier() {
            this.form.roomIdList = [];
            this.loadData();
        },
        handleRemove(file, fileList) {
            this.updateFileList(fileList);
        },
        handleSuccess(response, file, fileList) {
            let data = file.response;
            if (data.code != 0) {
                this.showError(data.msg);
                return;
            }
            this.updateFileList(fileList);
        },
        handleChange(file, fileList) {
            let domList = this.$el.querySelectorAll('.el-upload-list li .el-icon-close');
            let arr = [];
            for (let i = 0; i < fileList.length; i++) {
                if (fileList[i].response && fileList[i].response.code != 0) {
                    arr.push(domList[i]);
                }
            }
            for (let i = 0; i < arr.length; i++) {
                arr[i].dispatchEvent(new MouseEvent('click'));
            }
        },
        // handleError(err, file, fileList) {
        handleError(err, file) {
            this.showError(file.name + " 上传失败");
        },
        handleExceed() {
            this.showError("最多允许上传5个附件");
        },
        updateFileList(fileList) {
            let arr = [];
            fileList.forEach(file => {
                let { code, data } = file.response? file.response: {code:0,data:file};
                if (code == 0) {
                    arr.push({ name: data.originalName || data.name, url: data.url, });
                }
            });
            this.form.file = JSON.stringify(arr);
            this.fileList = arr;
        },
        handleDownLoad(ev) {
            let aDomList = this.$el.querySelectorAll('.el-upload-list li a');
            if (ev.target.tagName.toLowerCase() == 'a') {
                let i = -1;
                for (i = 0; i < aDomList.length; i++) {
                    if (aDomList[i] == ev.target) {
                        break;
                    }
                }
                if (i != -1 && i < this.fileList.length) {
                    let file = this.fileList[i];
                    let { data } = file.response? file.response: { data: file };
                    let filename = data.originalName || data.name;
                    let url = data.url;
                    http.download(url, { filename: filename, type: "application/octet-stream" });
                }
            }
        },
    }
};
</script>

<style scoped>
</style>
